import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0c8dd224 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _03361505 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _7e180a4e = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _d9d7f856 = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _56d70f44 = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _4293ced8 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _0923d533 = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _6761e687 = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _52f20b80 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _a8ea1142 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _4679d2dc = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _efb44cc8 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _ff7e09f2 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _48b05c3e = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _fc267d36 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _63435d04 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _798c8d70 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _50ed7d0b = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _4eb29dba = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _3853ffa4 = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _2e523c12 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _07d132bd = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _3d50ca48 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _1d730a78 = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _68fe77bb = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _628f6402 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _797ec606 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _0b0520fe = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _73530f70 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _e97e65c8 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _3f493f7b = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _12a702b4 = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _423ec398 = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _6761abe0 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _bd798948 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _528df8bd = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _1670d3a2 = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _b94e6b2a = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _13baa95c = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _211a082b = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _5fdccfac = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _6bd99fd6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _0c8dd224,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _03361505,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _7e180a4e,
    name: "help"
  }, {
    path: "/verify-email",
    component: _d9d7f856,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _56d70f44,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _4293ced8,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _0923d533,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _6761e687,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _52f20b80,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _a8ea1142,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _4679d2dc,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _efb44cc8,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _ff7e09f2,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _48b05c3e,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _fc267d36,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _63435d04,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _798c8d70,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _50ed7d0b,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _4eb29dba,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _3853ffa4,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _2e523c12,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _07d132bd,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _3d50ca48,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _1d730a78,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _68fe77bb,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _628f6402,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _797ec606,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _0b0520fe,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _73530f70,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _e97e65c8,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _3f493f7b,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _12a702b4,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _423ec398,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _6761abe0,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _bd798948,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _528df8bd,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _1670d3a2,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _b94e6b2a,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _13baa95c,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _211a082b,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _5fdccfac,
    name: "blog-slug"
  }, {
    path: "/",
    component: _6bd99fd6,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
